import React, { useEffect, useState, useRef } from "react";
import { useMedia } from "use-media";
import { OnboardingSlider } from "../conversational-ai-platform";
import { OnboardingMobile } from "../conversational-ai-platform";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import { VideoElement2 } from "../../components/video";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { AppsIntegrations } from "../conversational-ai-platform";


const access_management = require("../../assets/landing_servicenow/ITC_02_access_menagement_IT_chatbot.png");
const asset_requests = require("../../assets/landing_servicenow/ITC_02_asset_request_IT_chatbot.png");
const password_resets = require("../../assets/landing_servicenow/ITC_02_reset_password_IT_chatbot.png");
const unlock_account = require("../../assets/landing_servicenow/ITC_02_unlock_account_IT_chatbot.png");
const user_provisioning = require("../../assets/landing_servicenow/ITC_02_user_provisioning_IT_chatbot.png");
const offboarding = require("../../assets/landing_servicenow/ITC_02_offboarding_IT_chatbot.png");
const onboarding = require("../../assets/landing_servicenow/ITC_02_onboarding_IT_chatbot.png");

const section_one = require("../../assets/images/landing_virtual_agent/paid_img_1.png");
const section_two = require("../../assets/images/landing_virtual_agent/paid_img_2.png");
const section_three = require("../../assets/images/landing_virtual_agent/paid_img_3.png");
const section_four = require("../../assets/images/landing_virtual_agent/paid_img_4.png");
const section_five = require("../../assets/images/landing_virtual_agent/paid_img_5.png");
const section_six = require("../../assets/images/landing_virtual_agent/paid_img_6.png");
const section_seven = require("../../assets/images/landing_virtual_agent/paid_img_7.png");
const section_eight = require("../../assets/images/landing_virtual_agent/paid_img_8.png");
const section_nine = require("../../assets/images/landing_virtual_agent/paid_img_9.png");
const section_ten = require("../../assets/images/landing_virtual_agent/paid_img_10.png");

const icon1 = require("../../assets/landing_servicenow/it_icon_1.png");
const icon2 = require("../../assets/landing_servicenow/it_icon_2.png");
const icon3 = require("../../assets/landing_servicenow/it_icon_3.png");
const icon4 = require("../../assets/landing_servicenow/it_icon_4.png");
const icon5 = require("../../assets/landing_servicenow/it_icon_5.png");
const icon6 = require("../../assets/landing_servicenow/it_icon_6.png");
const icon7 = require("../../assets/landing_servicenow/it_icon_7.png");
const icon8 = require("../../assets/landing_servicenow/it_icon_8.png");
const icon9 = require("../../assets/landing_servicenow/it_icon_9.png");
const icon10 = require("../../assets/landing_servicenow/it_icon_10.png");

const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");
const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");

const img = require("../../assets/images/landing_virtual_agent/iso_web.png");
const img_mob = require("../../assets/images/landing_virtual_agent/iso_mob.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const VidImg = require("../../assets/images/landing_virtual_agent/vid_img_vi_agent.png");

const OnboardSlide = require("../../assets/images/landing_virtual_agent/paid_onboarding_1.png");
const OnboardSlide1 = require("../../assets/images/landing_virtual_agent/paid_onboarding_2.png");
const OnboardSlide2 = require("../../assets/images/landing_virtual_agent/paid_onboarding_3.png");
const OnboardSlide3 = require("../../assets/images/landing_virtual_agent/paid_onboarding_4.png");
const OnboardSlide4 = require("../../assets/images/landing_virtual_agent/paid_onboarding_5.png");
const OnboardArrow = require("../../assets/images/onboarding/onboarding_arrow.svg");

const TickLanding = require("../../assets/images/onboarding/tick_landing.png");

const reviewer_image = require("../../assets/images/testimonials/review_hammer.png");
const hammer_logo = require("../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../../assets/images/testimonials/g2_logo.svg");
const quotes = require("../../assets/images/testimonials/quotes.svg");



const SlackTeamsChat = () => (
  <div className="chat_channels_trial icon_container_chatbot_integration">
    <div
      className="chat_channels_trial_btn font-section-normal-text-medium
 color-chat-channel font-size-tablet-view"
    >
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div
      className="chat_channels_trial_btn color-chat-channel font-section-normal-text-medium
margin-right-20 font-size-tablet-view"
    >
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
    <div
      className="chat_channels_trial_btn font-section-normal-text-medium
 color-chat-channel font-size-tablet-view"
    >
      <img
        src={require("../../assets/images/features_images/features_chat.png")}
        alt="chatwidget"
      />
      Chat Widget
    </div>
  </div>
);

export default function LandingPage() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: unlock_account,
      title: "Unlock Account",
      tabFor: "vertical-tab-one",
    },
    {
      image: password_resets,
      title: "Password Reset",
      tabFor: "vertical-tab-two",
    },
    {
      image: user_provisioning,
      title: "User Provisioning",
      tabFor: "vertical-tab-three",
    },
    {
      image: access_management,
      title: "Access Management",
      tabFor: "vertical-tab-four",
    },
    {
      image: asset_requests,
      title: "Asset Requests",
      tabFor: "vertical-tab-five",
    },
    {
      image: onboarding,
      title: "Onboarding",
      tabFor: "vertical-tab-six",
    },
    {
      image: offboarding,
      title: "Offboarding",
      tabFor: "vertical-tab-seven",
    },
  ];

  const slideWidth = 100;

  const handlersTop = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementTopOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementTopOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handlersBottom = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementTopOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementTopOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = null; // speed in milliseconds to show each slide
  const loop = true;
  const [items, setItems] = React.useState([]);

  const parentRef = useRef(null);

  const [offsetTopTabs, setOffsetTopTabs] = React.useState(0);

  function incrementTopOffset() {
    if (offsetTopTabs === total - 1) {
      setOffsetTopTabs(loop ? 0 : offsetTopTabs);
    } else {
      // console.log("setOffset", offset)
      setOffsetTopTabs(offsetTopTabs + 1);
    }
  }

  function decrementTopOffset() {
    if (offsetTopTabs === 0) {
      setOffsetTopTabs(loop ? total - 1 : offsetTopTabs);
    } else {
      setOffsetTopTabs(offsetTopTabs - 1);
    }
  }

  const [offsetBottomTabs, setOffsetBottomTabs] = React.useState(0);

  function incrementBottomOffset() {
    if (offsetBottomTabs === total - 1) {
      setOffsetBottomTabs(loop ? 0 : offsetBottomTabs);
    } else {
      // console.log("setOffset", offset)
      setOffsetBottomTabs(offsetBottomTabs + 1);
    }
  }

  function decrementBottomOffset() {
    if (offsetBottomTabs === 0) {
      setOffsetBottomTabs(loop ? total - 1 : offsetBottomTabs);
    } else {
      setOffsetBottomTabs(offsetBottomTabs - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;

  useInterval(() => {
    if (loaded && enabled && offsetTopTabs < total) {
      incrementTopOffset();
    }
  }, speed);

  const [header, setHeader] = useState("top_menu_sticky");

  // const [dropdown, setDropdown] = useState("HR Chatbot");
  // const [isOpen, setOpen] = React.useState(false);

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  // console.log("offset", offset)

  // console.log("FeatureHomePage", offset)
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="FREE Virtual Agent"
        description="Conversational AI Automation platform to automate ITSM Ticketing tasks. Learn how to automate IT & HR support with AI Chatbot with process automation."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Impact of AI in ITSM with IT Helpdesk Automation | IT Help Desk Chatbot | Workativ Assistant"
        ogDescription="Conversational AI Automation platform to automate ITSM Ticketing tasks. Learn how to automate IT & HR support with AI Chatbot with process automation."
      />
      <div className="paid_main_con landing-page-vi-agent">
        <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT" hideMobileNavButton={true} >
          <section
            className=" wv-landing-page-1 --green-bg wv-banner-h320 paid-landing-page"
            id="scrollToHome"
            ref={parentRef}
          >
            <div className="container pt-5 pb-5">
              <div className="row">
                <div className="col-sm-6 col-12 paid-campaign-left">
                  <h1 className="font-section-header">FREE Virtual Agent</h1>
                  <h4 className="font-section-normal-text-medium">
                    Free to sign up, free to use.
                  </h4>
                  <RequestForm
                    isFooterForm={false}
                    parentContainerRef={parentRef}
                  />
            
                </div>
                <div className="col-sm-6 col-12 paid-campaign-right ">
                  <h5 className="font-section-normal-text-medium right_header-paid">
                    Everything you need for an amazing Virtual Agent
                  </h5>
                  <ul className="list_paid_camp font-section-normal">
                    <li>
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span>Pre-built Virtual Agent</span>
                    </li>

                    <li>
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span>
                        App Workflows for IT tools (Ticketing, User provisioning
                        and more)
                      </span>
                    </li>

                    <li>
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span> Automate FAQs</span>
                    </li>
                    <li>
                      {" "}
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span>Live Agent Handover</span>{" "}
                    </li>
                    <li>
                      {" "}
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span>Fully customizable with no-code.</span>{" "}
                    </li>
                    <li>
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span>
                        Integrate bot with 70+ apps, 600+ app actions instantly{" "}
                      </span>
                    </li>
                    <li>
                      <img classname="tick_landing" src={TickLanding}></img>
                      <span>
                        Multi-channel bot – Slack, MS Teams, Widget etc.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
             
            </div>
          </section>
          {isSmall ? (
            <OnboardingMobile
              header={onboardoingSliderData.header.mobile}
              sliders={onboardoingSliderData.sliders}
              additionalContainerClass="float-left"
            />
          ) : (
            <OnboardingSlider
              header={onboardoingSliderData.header.desktop}
              sliders={onboardoingSliderData.sliders}
            />
          )}
<RatingHomePage />
          <div className="servicenow_virtual">
            <section className="features_img_left pl-10-tage background-paid-image landing_page trial_page_img bg_landing_white pb-0 d-block">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-12 mob_slider_pad_0 slider-landing-chatbot">
                    <div className="center_feature_wrapper">
                      <h2 className="font-section-sub-header">
                        Automate 80% of your repetitive IT support issues
                      </h2>
                      <h4
                        className="font-section-sub-header-small"
                        style="text-align: center"
                      >
                        Core Virtual Agent Features
                      </h4>
                    </div>

                    {isSmall ? (
                      <MobileTabs sections={mobileTablsTopData.sections} />
                    ) : (
                      <TabProvider
                        defaultTab={
                          isSmall ? `section${offsetTopTabs + 1}` : "section1"
                        }
                        vertical
                      >
                        <div className="int_landing_page" {...handlersTop}>
                          <TabList className="horizontal_slider">
                            <Tab
                              offset={slides[offsetTopTabs + 0]}
                              tabFor="section1"
                            >
                              1. Virtual Agent Builder
                            </Tab>

                            <Tab
                              offset={slides[offsetTopTabs + 1]}
                              tabFor="section2"
                            >
                              2. Workflow Automation
                            </Tab>

                            <Tab
                              offset={slides[offsetTopTabs + 2]}
                              tabFor="section3"
                            >
                              3. Automate FAQs
                            </Tab>

                            <Tab
                              offset={slides[offsetTopTabs + 3]}
                              tabFor="section4"
                            >
                              4. Virtual Agent Marketplace
                            </Tab>
                            <Tab
                              offset={slides[offsetTopTabs + 4]}
                              tabFor="section5"
                            >
                              5. Omnichannel
                            </Tab>
                          </TabList>

                          <TabPanel
                            offset={slides[offsetTopTabs + 0]}
                            tabId="section1"
                          >
                            <Section1 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetTopTabs + 1]}
                            tabId="section2"
                          >
                            <Section2 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetTopTabs + 2]}
                            tabId="section3"
                          >
                            <Section3 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetTopTabs + 3]}
                            tabId="section4"
                          >
                            <Section4 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetTopTabs + 4]}
                            tabId="section5"
                          >
                            <Section5 />
                          </TabPanel>
                        </div>
                      </TabProvider>
                    )}
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-12  padding-top-10 mt-1 slider-landing-chatbot">
                    <h4
                      className="font-section-sub-header-small"
                      style="text-align: center"
                    >
                      Pro Virtual Agent Features
                    </h4>

                    {isSmall ? (
                      <MobileTabs sections={mobileTablsBottomData.sections} />
                    ) : (
                      <TabProvider
                        defaultTab={
                          isSmall
                            ? `section${offsetBottomTabs + 5}`
                            : "section6"
                        }
                        vertical
                      >
                        <div className="int_landing_page" {...handlersBottom}>
                          <TabList className="horizontal_slider">
                            <Tab
                              offset={slides[offsetBottomTabs + 5]}
                              tabFor="section6"
                            >
                              6. Analytics
                            </Tab>

                            <Tab
                              offset={slides[offsetBottomTabs + 6]}
                              tabFor="section7"
                            >
                              7. Agent Handover
                            </Tab>

                            <Tab
                              offset={slides[offsetBottomTabs + 7]}
                              tabFor="section8"
                            >
                              8. Approvals
                            </Tab>

                            <Tab
                              offset={slides[offsetBottomTabs + 8]}
                              tabFor="section9"
                            >
                              9. Training
                            </Tab>
                            <Tab
                              offset={slides[offsetBottomTabs + 9]}
                              tabFor="section10"
                            >
                              10. On-prem App Connect
                            </Tab>
                          </TabList>

                          <TabPanel
                            offset={slides[offsetBottomTabs + 5]}
                            tabId="section6"
                          >
                            <Section6 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetBottomTabs + 6]}
                            tabId="section7"
                          >
                            <Section7 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetBottomTabs + 7]}
                            tabId="section8"
                          >
                            <Section8 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetBottomTabs + 8]}
                            tabId="section9"
                          >
                            <Section9 />
                          </TabPanel>
                          <TabPanel
                            offset={slides[offsetBottomTabs + 9]}
                            tabId="section10"
                          >
                            <Section10 />
                          </TabPanel>
                        </div>
                      </TabProvider>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div class="container">
            <div className="build_needs">
              <div
                class="col-lg-12 col-md-12 col-12 pl-0 center_features_header"
                style="margin-bottom: 20px;"
              >
                <h2 className="font-section-header">
                  Virtual Agent Demo - 5 mins
                </h2>
              </div>
            </div>
          </div>
          <VideoElement2
            videoURL={"https://www.youtube-nocookie.com/embed/MD0dK2usQjw"}
            imgURL={VidImg}
            imgMob={VidImg}
          />
          <TestimonialsHomePage />
          <AppsIntegrations
            header={
              <>
                <h3 className="font-section-header mb-2">
                  Integrate Virtual Agent with 70+ Apps, and 1000s of App
                  Workflows instantly. No coding.
                </h3>
              </>
            }
          />
          <RequestForm isFooterForm={true} additionalClass={"margin-top-40"} />

          <section className="center_image_home cards_landing_page pb-0 mb-0">
            <div className="container">
              <div className="col-md-12">
                <div className="row">
                  <div className="main-slider-left">
                    <h3 className="font-section-header">
                      Your data is yours and you control it
                    </h3>
                    <p className="font-section-normal-text">
                      Workativ has implemented robust security processes and
                      controls that are in compliance with industry-leading
                      standards and regulations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="automation_steps iso">
            <div className="container">
              {isSmall ? (
                <picture>
                  <img className="center" src={img_mob} />
                </picture>
              ) : (
                <picture>
                  <img className="center" src={img} />
                </picture>
              )}
            </div>
          </section>
          <Footer />
        </Layout>
      </div>
    </>
  );
}
function RatingHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
  <section class="testimonial_background_main_container testimonial_background_rating_padding_mobile float-left">
    <div className="container">
    <div className="row">
      <div className="features_change_img-center">
        <h5 className="text-center font-medium font-section-normal-text-medium">
          Join hundreds of industry leaders
        </h5>

        {isSmall ? (
          <img className="" src={bannerImageMobile} />
        ) : (
          <img className="" src={customerBanner} />
        )}
      </div>
     </div>
    </div>
      <div className="container">
        <div className="row">
          <div className="rating_box_main_container">
            <div className="rating_left_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                
                Very positive experience. We are able to grow the bot based on our internal needs. The cost and support can’t be beat for all the features the tool provides.
              </p>

              <img className="capterra_logo_left" src={capterra_logo} />
            </div>
            <div className="rating_right_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                
                The deployment and support team was exceptional! They worked hard to make sure our rollout was successful.
              </p>
              <img src={g2_logo} className="g2_logo_right"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function TestimonialsHomePage() {
  const isSmall = useMedia({ maxWidth: "820px" });
  return isSmall ? (
    <section class="testimonial_background_main_container_mobile float-left">
      <div class="container">
        <div class="row">
          <div className="testimonial_feedback_box_mobile">
            <div className="testimonial_feedback_top_content_box_mobile">
              <p className="font-section-normal-text">
              <span><img src={quotes} className="quotes_image_testimonial"></img></span>The setup and building of the Workativ Chatbot I felt was
                extremely well supported! My questions were answered quickly and
                at no point did I feel unsupported. I had multiple Zoom meetings
                through the process where we would meet to go through the build.
              </p>
              <p className="font-section-normal-text">
                I felt listened through what we absolutely had to have for this
                tool to be rolled out in our environment. Throughout the process
                we kept looking back at the business requirements to make sure
                we were aligning properly.
              </p>
            </div>
            <div className="testimonial_review_bottom_image_box_mobile">
              <div className="bottom_image_box_left">
                <img
                  src={reviewer_image}
                  className="testimonial_reviewer_image"
                ></img>
              </div>
              <div className="bottom_designation_box_right_mobile">
                <h5
                  className="font-section-sub-header"
                  style={{ color: "#526CB1" }}
                >
                  Dan Bottiger
                </h5>
                <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                  Director of Information Systems
                </h5>
                <img
                  src={hammer_logo}
                  className="testimonial_hammer_logo_mobile"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section class="testimonial_background_main_container float-left">
      <div className="container">
        <div className="row">
          <div className="testimonial_feedback_box">
            <div className="testimonial_feedback_left_image_box">
              <img
                src={reviewer_image}
                className="testimonial_reviewer_image"
              ></img>
            </div>
            <div className="testimonial_feedback_right_content_box">
              <p className="font-section-normal-text line-height-2">
              <span><img src={quotes} className="quotes_image_testimonial"></img></span>The setup and building of the Workativ Chatbot I felt was
                extremely well supported! My questions were answered quickly and
                at no point did I feel unsupported. I had multiple Zoom meetings
                through the process where we would meet to go through the build.
                When the build hit a hurdle, the resolution was found and
                implemented within 24-48 hours. Through this communication was
                excellent.
              </p>
              <p className="font-section-normal-text line-height-2">
                {" "}
                I felt listened through what we absolutely had to have for this
                tool to be rolled out in our environment. Throughout the process
                we kept looking back at the business requirements to make sure
                we were aligning properly.
              </p>
              <div className="designation_and_hammer_logo">
                <div>
                  <h5
                    className="font-section-sub-header"
                    style={{ color: "#526CB1" }}
                  >
                    Dan Bottiger
                  </h5>
                  <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                    Director of Information Systems
                  </h5>
                </div>
                <img
                  src={hammer_logo}
                  className="testimonial_hammer_logo"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function Footer() {
  return (
    <section className="landing_bg">
      <div className="footer_landing footer_background">
        <div className="container">
          <div className="footer_landing_row">
          <a href="https://workativ.com/conversational-ai-platform">
            <img
              style={{ cursor: "pointer" }}
              src={require("../../assets/images/workativ-logo.png")}
              alt="Workativ"
            />
            </a>
            <div className="text-right bottom-text-paid-landing-page sm-align-left">
              <p>
                <span>Workativ Copyrights © 2022. All rights reserved.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Section1() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing"
      id="section1"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img src={icon1} />
            </div>
            <h3 className="font-section-sub-header">
              Cover a wide range of topics for your Virtual Agent
            </h3>
            <p className="font-section-normal-text">
              Use workativ’s no-code platform to add, remove or optimise new
              topics for the Virtual Agent easily based on your requirement. Use
              our no-code canvas to create engaging employee experiences.
            </p>
            <p className="second_p font-section-normal-text color-black">
              Go digital with Virtual Agent to deliver employee support –
              faster, smarter, and easier.{" "}
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img src={section_one} alt="IT Helpdesk Chatbot"className="tablet-image-size-landing"  />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section2() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing"
      id="section4"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img src={icon2} />
            </div>
            <h3 className="font-section-sub-header">
              Superpower your Virtual Agent with in-built workflow automation
            </h3>
            <p className="font-section-normal-text">
              Automate IT requests from Virtual Agent using our pre-built app
              workflows. Integrate Virtual Agent with 70+ apps in a click and
              provide instant auto-resolution to your end-user issues or service
              requests instantly.{" "}
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              src={section_two}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section3() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing"
      id="section3"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img src={icon3} />
            </div>
            <h3 className="font-section-sub-header">Automate all your FAQs</h3>
            <p className="font-section-normal-text">
              Simple and easy to use UI to automate your FAQs in minutes. Key
              user queries, utterances and answers to automate all your manual
              FAQs or simply use pre-built templates* from marketplace that
              covers wide variety of FAQ topics. Just download, activate and
              go-live in simple steps.{" "}
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              src={section_three}
              alt="Deploy IT Helpdesk Chatbot on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section4() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing"
      id="section4"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img src={icon4} />
            </div>
            <h3 className="font-section-sub-header">
              Virtual Agent Marketplace
            </h3>
            <p className="font-section-normal-text">
              A marketplace that covers all - Virtual Agents, App Integrations &
              Workflows, Dialogs*, FAQs* and much more. Easily download
              pre-built Virtual Agent, connect them with pre-built app
              integrations and app workflows, and go live in few clicks. No
              coding.{" "}
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              src={section_four}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section5() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing"
      id="section5"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <div className="iocn_virtual">
              <img src={icon5} />
            </div>
            <h3 className="font-section-sub-header">
              Virtual Agent on Teams, Slack, or as Chat Widget
            </h3>
            <p className="font-section-normal-text">
              Turn your favorite collaboration app into modern employee
              self-service by deploying Virtual Agent on your Teams, Slack or as
              Widget on your self-help portal in few clicks. Customize the
              end-user experience by applying your company branding, logo etc.{" "}
            </p>
            <SlackTeamsChat />
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              src={section_five}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
              className="tablet-image-size-landing" 
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section6() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-40-landing"
      id="section10"
    >
      <div className="container-fluid">
        <div className="row mobile-flex-flow">
          <div className="col-md-6">
            <img
              src={section_six}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>

          <div className="col-md-6 feature_page_content_left_landing align-items-start">
            <div className="iocn_virtual">
              <img src={icon10} />
            </div>
            <h3 className="font-section-sub-header">
              Analytics & logs - deep dive on Virtual Agent interactions
            </h3>
            <p className="font-section-normal-text">
              Refine Virtual Agent conversations by analyzing end-user and
              Virtual Agent interactions using workativ’s analytics and logs
              capability. Developing improvements for the Virtual Agent is easy
              as a breeze when you know what went under the hood.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section7() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-40-landing"
      id="section6"
    >
      <div className="container-fluid">
        <div className="row mobile-flex-flow">
          <div className="col-md-6 mobile-padding-top-20">
            <img
              src={section_seven}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>

          <div className="col-md-6 feature_page_content_left_landing align-items-start align-items-start">
            <div className="iocn_virtual">
              <img src={icon6} />
            </div>
            <h3 className="font-section-sub-header">Live Agent Handover</h3>
            <p className="font-section-normal-text">
              Workativ offers seamless ‘agent handover’ of Virtual Agent to live
              agents with complete context and conversation history so your
              agents can pick up right where the Virtual Agent left, avoiding
              any user frustration – but most importantly solving end-user
              issues right away.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section8() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-40-landing"
      id="section8"
    >
      <div className="container-fluid">
        <div className="row mobile-flex-flow">
          <div className="col-md-6  ">
            <img
              src={section_eight}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
              className="tablet-image-size-landing" 
            />
          </div>

          <div className="col-md-6 feature_page_content_left_landing align-items-start">
            <div className="iocn_virtual">
              <img src={icon8} />
            </div>
            <h3 className="font-section-sub-header">
              Approve your Virtual Agent requests in seconds
            </h3>
            <p className="font-section-normal-text">
              Automating workplace requests can sometimes requires manager or
              special approval. Workativ helps you automate and manage approvals
              for employee requests from Virtual Agent easily out-of-the box
              with approval management capability. Setting up approvals,
              notification, reminders, expiry, and tracking made simple for you.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section9() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-40-landing"
      id="section9"
    >
      <div className="container-fluid">
        <div className="row mobile-flex-flow">
          <div className="col-md-6  ">
            <img
              src={section_nine}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>

          <div className="col-md-6 feature_page_content_left_landing align-items-start">
            <div className="iocn_virtual">
              <img src={icon9} />
            </div>
            <h3 className="font-section-sub-header">
              Great experience comes from small improvements over time{" "}
            </h3>
            <p className="font-section-normal-text">
              Refine end user experience by improving Virtual Agent interactions
              using workativ’s training module. Very helpful in fine-tuning your
              end-user experience. {" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function Section10() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-40-landing"
      id="section6"
    >
      <div className="container-fluid">
        <div className="row mobile-flex-flow">
          <div className="col-md-6  ">
            <img
              src={section_ten}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>

          <div className="col-md-6 feature_page_content_left_landing align-items-start">
            <div className="iocn_virtual">
              <img src={icon7} />
            </div>
            <h3 className="font-section-sub-header">
              On-prem app secure connector
            </h3>
            <p className="font-section-normal-text">
              Use workativ’s secure connector to connect Virtual Agent with your
              on-prem apps behind your corporate firewall to execute workflows
              without a hitch. No need to open ports.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

const onboardoingSliderData = {
  header: {
    desktop: (
      <h2 className="font-section-sub-header">Go live in 5 easy steps</h2>
    ),
    mobile: (
      <h3 className="font-section-sub-header">Go live in 5 easy steps</h3>
    ),
  },

  sliders: [
    {
      title: "Sign up",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Sign up
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                You can create a new or download pre-built Virtual Agent from
                marketplace
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Sign up</h3>
            <p className="font-section-normal-text">
              You can create a new or download pre-built Virtual Agent from
              marketplace
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Download Virtual Agent",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Download Virtual Agent
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Virtual Agent templates are pre-built with conversations
                covering most common and repetitive support topics. Easily
                download Virtual Agent in one-click, add new or edit existing
                support topics in minutes.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Download Virtual Agent
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Virtual Agent templates are pre-built with conversations covering
              most common and repetitive support topics. Easily download Virtual
              Agent in one-click, add new or edit existing support topics in
              minutes.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Connect App Workflows",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Connect App Workflows
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Connecting Virtual Agent with your apps and building app
                workflows requires a lot of development efforts, time, and
                costs. But workativ provides pre-built app integrations and app
                workflows so you can connect your Virtual Agent with your app
                and automate tasks instantly. No coding.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Connect App Workflows
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Connecting Virtual Agent with your apps and building app workflows
              requires a lot of development efforts, time, and costs. But
              workativ provides pre-built app integrations and app workflows so
              you can connect your Virtual Agent with your app and automate
              tasks instantly. No coding.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Test your Virtual Agent",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Virtual Agent Testing
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Test your Virtual Agent in real time using “Try Me”. Make
                adjustments, add or edit conversations, fine tune user
                interactions, and review your bot conversations on the go before
                you go-live.
              </p>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Virtual Agent Testing</h3>
            <p className="font-section-normal-text">
              Test your Virtual Agent in real time using “Try Me”. Make
              adjustments, add or edit conversations, fine tune user
              interactions, and review your bot conversations on the go before
              you go-live.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Go Live",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Deploy on Teams, Slack, or as Chat Widget
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Turn your favorite collaboration app into modern employee
                self-service by deploying Virtual Agent on your Teams, Slack or
                as Widget on your self-help portal in few clicks. Customize the
                end-user experience by applying your company branding, logo etc.
              </p>
              <div className="icons_container_onboarding">
                <div className="icon_text">
                  <img
                    class="slack_feature_icon_onboarding"
                    src={FeatureSlack}
                  ></img>
                  <h6 className="font-section-small-text-medium font-size-tablet-view  mb-0 pt-1">
                    Slack
                  </h6>
                </div>

                <div className="icon_text">
                  <img
                    class="teams_feature_icon_onboarding"
                    src={FeatureTeams}
                  ></img>
                  <h6 className="font-section-small-text-medium font-size-tablet-view  mb-0 pt-1">
                    Teams
                  </h6>
                </div>
                <div className="icon_text">
                  <img
                    class="chat_feature_icon_onboarding"
                    src={FeatureChat}
                  ></img>
                  <h6 className="font-section-small-text-medium font-size-tablet-view  mb-0 pt-1">
                    Chat Widget
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Deploy Bot</h3>
            <p className="font-section-normal-text">
              Turn your favorite collaboration app into modern employee
              self-service by deploying Virtual Agent on your Teams, Slack or as
              Widget on your self-help portal in few clicks. Customize the
              end-user experience by applying your company branding, logo etc.
            </p>
            <div className="icons_container_onboarding_mobile">
              <img
                class="slack_feature_icon_onboarding_mobile"
                src={FeatureSlack}
              ></img>
              <h6>Slack</h6>

              <img
                class="teams_feature_icon_onboarding_mobile"
                src={FeatureTeams}
              ></img>
              <h6>Teams</h6>

              <img
                class="chat_feature_icon_onboarding_mobile"
                src={FeatureChat}
              ></img>
              <h6>Chat Widget</h6>
            </div>
          </div>

          <div className="image_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
    },
  ],
};

const mobileTablsTopData = {
  sections: [
    {
      tab: <span>Virtual Agent Builder</span>,
      section: <Section1 />,
    },
    {
      tab: <span>Workflow Automation</span>,
      section: <Section2 />,
    },
    {
      tab: <span> Automate FAQs</span>,
      section: <Section3 />,
    },
    {
      tab: <span>Virtual Agent Marketplace</span>,
      section: <Section4 />,
    },
    {
      tab: <span> Omnichannel</span>,
      section: <Section5 />,
    },
  ],
};

const mobileTablsBottomData = {
  sections: [
    {
      tab: <span>Analytics</span>,
      section: <Section6 />,
    },
    {
      tab: <span>Agent Handover</span>,
      section: <Section7 />,
    },
    {
      tab: <span>Approvals</span>,
      section: <Section8 />,
    },
    {
      tab: <span>Training</span>,
      section: <Section9 />,
    },
    {
      tab: <span>On-prem App Connect</span>,
      section: <Section10 />,
    },
  ],
};

export function MobileTabs(props) {
  const [p, setP] = useState(0);
  const [finished, setFinished] = useState([0]);
  const intervalRef = useRef(null);

  const additionalContainerClass = props.additionalContainerClass;
  const onboardingSliderComponents = props.sections;

  // console.log("MobileTabs :::: ", onboardingSliderComponents);

  const Step = onboardingSliderComponents[p]
    ? () => onboardingSliderComponents[p].tab
    : () => null;

  console.log("MobileTabs :::: 2", onboardingSliderComponents[p], Step);
  function update() {
    var element = document.getElementById(`stepprogressBar${p}`);
    var width = 1;
    intervalRef.current = setInterval(scene, 100);
    var element1 = document.getElementById(`stepprogressBar${0}`);
    var element2 = document.getElementById(`stepprogressBar${1}`);
    var element3 = document.getElementById(`stepprogressBar${2}`);
    var element4 = document.getElementById(`stepprogressBar${3}`);

    function scene() {
      if (width >= 100) {
        if (p == 4) {
          if (element1) element1.style.width = "0%";
          if (element2) element2.style.width = "0%";
          if (element3) element3.style.width = "0%";
          if (element4) element4.style.width = "0%";
        }

        clearInterval(intervalRef.current);
        setFinished(p == 4 ? [0] : [...finished, p + 1]);
        setP(p < 4 ? p + 1 : 0);
      } else {
        width++;
        if (p < 4) {
          if (element) {
            element.style.width = width + "%";
          }
        }
      }
    }
  }

  useEffect(() => {
    // setTimeout(() => {
    //   update();
    // });

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [p]);

  function clickHandling(index) {
    const baseProg = [0, 1, 2, 3, 4];
    clearInterval(intervalRef.current);
    baseProg
      .filter((i) => i > index && i != 4)
      .reduce((acc, val) => {
        var element = document.getElementById(`stepprogressBar${val}`);
        element.style.width = "0%";

        return acc;
      }, 0);

    baseProg
      .filter((i) => (index == 0 ? i <= index : i < index))
      .reduce((acc, val) => {
        var element = document.getElementById(`stepprogressBar${val}`);
        element.style.width = "100%";

        return acc;
      }, 0);
    setFinished(baseProg.filter((i) => i <= index));
    setP(index);
  }

  function nextOrPrevSlide(type) {
    const baseProg = [0, 1, 2, 3, 4];
    clearInterval(intervalRef.current);
    setFinished(baseProg.filter((i) => i <= (type == "next" ? p + 1 : p - 1)));
    setP(type == "next" ? p + 1 : p - 1);
  }

  return (
    <section
      className={`width-100-percent  ${
        additionalContainerClass ? additionalContainerClass : ""
      }`}
    >
      <div>
        <div className="onboarding_mobile_progress">
          <div
            className={
              p != 0
                ? "onboarding_mob_arrow_right rotate-180"
                : "onboarding_mob_arrow_left rotate-180"
            }
            onClick={() => p != 0 && nextOrPrevSlide("prev")}
          >
            &#10132;
          </div>

          <h5 className="mobile_step_header ">
            {" "}
            <Step />
          </h5>
          <div
            className={
              p != 4
                ? "onboarding_mob_arrow_right"
                : "onboarding_mob_arrow_left"
            }
            onClick={() => p != 4 && nextOrPrevSlide("next")}
          >
            &#10132;
          </div>
        </div>
        {onboardingSliderComponents.map((slid, i) =>
          i == p ? slid.section : null
        )}
      </div>
    </section>
  );
}
